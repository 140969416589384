//---------------------------------------------
//
//  Mixin: Google Fonts
//
//    1. Google's names for font files
//    2. Loading a single font
//    3. Loading multiple fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Google's names for font files
//---------------------------------------------

$fontWeightNames: (
  100: 'Thin',
  200: 'ExtraLight',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'SemiBold',
  700: 'Bold',
);

//---------------------------------------------
// 2. Loading a single font
//---------------------------------------------

@mixin googleFont($font) {
  $fontFamily: map-get($font, 'name');
  $folderName: map-get($font, 'folder');
  $fileName: map-get($font, 'files');
  $weights: map-get($font, 'weights');
  @each $weight in $weights {
    $weightName: map-get($fontWeightNames, $weight);
    @font-face {
      font-family: '#{$fontFamily}';
      font-weight: $weight;
      font-display: swap;
      src: url('../../fonts/#{$folderName}/#{$fileName}-#{$weightName}.eot'); /* IE 9 - 11 */
      src: url('../../fonts/#{$folderName}/#{$fileName}-#{$weightName}.eot?#iefix')
          format('embedded-opentype'),
        /* IE 9 - 11 */
          url('../../fonts/#{$folderName}/#{$fileName}-#{$weightName}.woff2')
          format('woff2'),
        url('../../fonts/#{$folderName}/#{$fileName}-#{$weightName}.woff')
          format('woff');
    }
  }
}

//---------------------------------------------
// 3. Loading multiple fonts
//---------------------------------------------

@mixin googleFonts($fonts) {
  @each $font in $fonts {
    @include googleFont($font);
  }
}

@mixin poppins($weight) {
  font-family: 'Poppins', sans-serif;
  font-weight: $weight;
}

@mixin Montserrat($weight) {
  font-family: 'Montserrat', sans-serif;
  font-weight: $weight;
}

@mixin border-radius($pixel...) {
  border-radius: $pixel;
}

//---------------------------------------------
// 4. Viewport sizes
//---------------------------------------------
@mixin smallPhone {
  @media (max-width: 420px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 767.8px) {
    @content;
  }
}
@mixin ipad {
  @media (max-width: 1023.8px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1023.9px) {
    @content;
  }
}

//---------------------------------------------
// 5. Main style groups
//---------------------------------------------

@mixin autoLayout {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 8px;
}
