.serviceLabel_item_container {
  position: relative;

  cursor: pointer;
}

.disabled_service {
  cursor: default !important;

  * {
    color: $white;
  }

  .ctaService {
    background-color: $primary;
    color: $white;
    opacity: 1 !important;
  }
}

.disable_by_services {
  opacity: 0.6;
}

.disable_cursor {
  cursor: default !important;
}

.disabled_tooltip_container {
  display: none;
}

.disabled_service:hover .disabled_tooltip_container {
  display: block;
  position: absolute;
  z-index: $z_index_sixth_floor;
  color: black;
  font-weight: $fWeight600;
  font-size: $font12;
  width: 214px;
  background-color: $tooltips_main_bg_color;
  padding: 8px;
  border-radius: 8px;
  top: 35px;
  * {
    color: black;
  }
}

.ctaService {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #07364b;
  padding: 5px 9px;
  border: 1px solid #07364b;
  border-radius: 12px;
  text-decoration: none;
  position: relative;
  opacity: $main_disabled_opacity;

  i {
    margin-left: 4px;
  }

  &.active {
    color: #fff;
    background: #07364b;

    i {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &[aria-expanded='true'] {
    color: #fff;
    background: #07364b;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    i {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
