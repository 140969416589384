.btn {
  @include poppins(600);
  line-height: 166.5%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px;

  &.cta-primary {
    color: $white;
    background-color: $primary;
  }

  &.cta-outline-primary {
    border: 1px solid $primary;
  }

  &.cta-rounded {
    @include border-radius(32px);
  }
}

.btn-rounded {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: $white;
  border: 1px solid $border;
  @include border-radius(50%);
}

.deleteDate {
  display: flex;
  width: 18px;
  height: 18px;
  background: red;
  border-radius: 4px;
  margin-left: 8px;
  margin-top: 3px;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    width: 11px;
    height: 2px;
    background: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.btn_main {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.btn_main_light {
  border: $main_gray_border;
  border-radius: $primary_border_radius;
  padding: 10px 18px;
  background-color: $white;
  color: $primary;
}

.btn_main_dark {
  border-radius: $primary_border_radius;
  padding: 10px 18px;
  background-color: $primary;
  color: $white;
  border: 1px solid $primary;
  * {
    color: $white;
  }
  a:hover {
    color: $white;
    width: 100%;
  }
}

.edit_btn {
  @include Montserrat(400);
  font-size: $font12;
  line-height: 150%;
  display: inline-flex;
  color: $red;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: 0.4s;
  width: fit-content;
  &:hover {
    background-color: $tours_step_main_bg_gray;
  }
}

.save_btn {
  @include Montserrat(400);
  font-size: $font12;
  line-height: 150%;
  display: inline-flex;
  color: $red;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: 0.4s;
  &:hover {
    background-color: $tours_step_main_bg_gray;
  }
}

.cancel_btn {
  font-size: $font12;
  line-height: 150%;
  display: inline-flex;
  color: $gray-900;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: 0.4s;
  &:hover {
    background-color: $tours_step_main_bg_gray;
  }
}

.forward_btn {
  min-height: 22px;
  display: flex;
  padding: 2px 6px 2px 8px !important;
  gap: 4px;

  border-radius: $secondary_border_radius;
  border: none;

  background-color: $primary;
  color: $white;
}

.disabled_edit {
  opacity: 0.3;
  cursor: default !important;
}

.back_to_order {
  margin-right: 10px;
  font-size: 14px !important;
  padding: 9px !important;
}
