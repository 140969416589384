@import "../../mixins.scss";

.download_progress_wrapper {
  height: 230px;
  transition: height 0.5s;

  &.download_progress_wrapper_close {
    height: 100px;
    transition: height 0.5s;
  }

  .download_progress {
    display: flex;
    flex-direction: column;

    .download_progress_header {
      display: flex;
      align-items: center;
      border-radius: 5px 5px 0px 0px;
      background: #282828;
      width: 362px;
      height: 52.5px;

      .download_progress_title {
        font-family: NunitoSans-Regular, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: #ffffff;
        margin-right: 105px;
        margin-left: 15px;
      }

      .download_progress_up_down {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        background-color: #6a6a6a;
        border-radius: 50%;
        margin-right: 23px;
        cursor: pointer;
        transition: all 0.5s;

        svg {
          width: 17px;
          height: 11px;
        }

        &.download_progress_up_down_close {
          transition: all 0.5s;
          transform: rotate(180deg);
        }
      }

      .download_progress_close {
        cursor: pointer;
        width: 12px;
        height: 12px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    .download_progress_body {
      display: flex;
      height: 47px;
      width: 362px;
      align-items: center;
      background: #ededed;

      .download_progress_icon {
        margin-right: 18px;
        margin-left: 13px;
        width: 18px;
        height: 18px;

        svg {
          display: none;
        }
      }

      .download_progress_status {
        font-family: NunitoSans-Regular, "sans-serif";
        font-style: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.05em;
        color: #282828;

        .download_progress_in_progress {
          margin-right: 0.5rem;
          margin-left: 0.5rem;
        }

        .download_progress_all {
          margin-right: 0.5rem;
          margin-left: 0.5rem;
        }
      }

      .download_progress_loading {
        margin: 0 12px 5px auto;

        .download_progress_loading_done {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #42a040;
        }
      }
    }

    .download_progress_loading_line_wrapper {
      height: 2px;
      width: 362px;
      background-color: #c4c4c4;

      .download_progress_loading_line {
        height: 2px;
        width: 362px;
        background-color: #e71b4c;
      }
    }

    .download_progress_log {
      height: 131px;
      width: 362px;
      overflow: auto;
      background-color: #ffffff;
      border-radius: 0 0 5px 5px;
      transition: height 0.5s;
      overflow-x: hidden;

      &.download_progress_log_close {
        height: 0;
        transition: height 0.5s;
      }
    }
  }
}
