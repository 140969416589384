.delivered,
.updated {
  font-family: 'NunitoSans-SemiBold';
  cursor: inherit !important;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 4px;
  width: 9rem;
  height: 2rem;
  border: none;
  font-size: 1.4rem;

  .delivered_dates {
    font-family: 'NunitoSans-Regular';
    font-size: 8px;
  }
}

.updated {
  background-color: #f33c3c;
  border-color: #f33c3c;
}

.delivered {
  background-color: #20b774;
  border-color: #20b774;
}

@media (max-width: 1200px) {
  .delivered,
  .updated {
    padding: unset;
    width: 136px;
    height: 29px;
    border-radius: 4px;
    font-size: 11px;
  }
}
