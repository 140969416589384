// Bootstrap and its default variables
@import '../scss/variables';
@import '../css/bootstrap.min.css';
@import 'abstracts.scss/_mixins.scss';
@import '../scss/utilities/mixin.scss';
@import 'abstracts.scss/_variables.scss';
@import 'base.scss/_reset.scss';
@import 'base.scss/_typography.scss';
@import 'components.scss/_buttons.scss';
@import 'components.scss/_tabs.scss';
@import 'components.scss/_testimonial.scss';
@import 'components.scss/_video_modal.scss';
@import 'layout.scss/_header.scss';
@import 'layout.scss/_banner.scss';
@import 'layout.scss/_footer.scss';

/* CUSTOM STYLES
-------------------------------------------------- */
@font-face {
  font-family: NunitoSans-Regular;
  src: url('../assets/fonts/NunitoSans-Regular.ttf');
}

@font-face {
  font-family: NunitoSans-Italic;
  src: url('../assets/fonts/NunitoSans-Italic.ttf');
}

@font-face {
  font-family: NunitoSans-Bold;
  src: url('../assets/fonts/NunitoSans-Bold.ttf');
}

@font-face {
  font-family: NunitoSans-Black;
  src: url('../assets/fonts/NunitoSans-Black.ttf');
}

@font-face {
  font-family: NunitoSans-ExtraBold;
  src: url('../assets/fonts/NunitoSans-ExtraBold.ttf');
}

@font-face {
  font-family: NunitoSans-SemiBold;
  src: url('../assets/fonts/NunitoSans-SemiBold.ttf');
}

@font-face {
  font-family: NunitoSans-Light;
  src: url('../assets/fonts/NunitoSans-Light.ttf');
}

@font-face {
  font-family: Roboto-Bold;
  src: url('../assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: RobotoCondensed-Regular;
  src: url('../assets/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: Roboto-Light;
  src: url('../assets/fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: Roboto-Medium;
  src: url('../assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: Cardo-Bold;
  src: url('../assets/fonts/Cardo-Bold.ttf');
}

@font-face {
  font-family: Cardo-Italic;
  src: url('../assets/fonts/Cardo-Italic.ttf');
}

@font-face {
  font-family: Cardo-Regular;
  src: url('../assets/fonts/Cardo-Regular.ttf');
}

@font-face {
  font-family: Inter-Regular;
  src: url('../assets/fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: Inter-Bold;
  src: url('../assets/fonts/Inter-Bold.ttf');
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url('../assets/fonts/Inter-ExtraBold.ttf');
}

@font-face {
  font-family: Inter-SemiBold;
  src: url('../assets/fonts/Inter-SemiBold.ttf');
}

@font-face {
  font-family: Inter-Light;
  src: url('../assets/fonts/Inter-Light.ttf');
}

@font-face {
  font-family: Inter-ExtraLight;
  src: url('../assets/fonts/Inter-ExtraLight.ttf');
}

@font-face {
  font-family: Inter-Thin;
  src: url('../assets/fonts/Inter-Thin.ttf');
}

@font-face {
  font-family: Inter-Medium;
  src: url('../assets/fonts/Inter-Medium.ttf');
}

* {
  font-family: NunitoSans-Regular;
}

.header_shadow {
  background-color: #ffffff;
  box-shadow: 0 0 50px rgb(0 0 0 / 0.3);
  * {
    color: #000000 !important;
  }
  .nav-link {
    color: #000000 !important;
  }
}

.wrapper {
  overflow: hidden;
}

.title {
  text-align: center;

  h2 {
    font-size: $font40;
    color: $secondary_color;
  }
}

.highlights-sec {
  padding: 0 0 33px;

  .highlights-inner {
    padding: 25px 0 0 0;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      @include phone {
        justify-content: space-between;
        li {
          padding: 10px 0 !important;
        }
      }

      li {
        text-align: center;
        padding: 15px 30px;

        span {
          display: block;
        }

        h4 {
          font-size: $font16;
          color: $secondary_color;
          @include Poppins(400);
          margin: 15px 0 4px;
        }

        strong {
          @include Poppins(600);
          font-size: $font14;
          display: block;
          color: $secondary_color;
        }
      }
    }
  }

  .high_lights_title {
    text-transform: capitalize;
  }
}

/****** MATTERPORT SEC *****/
.matterport-inner {
  position: relative;
  margin: 40px 0 0 0;
  > iframe {
    border-radius: 12px;
  }

  > .img-fluid {
    @media (min-width: 1000px) {
      height: 711px;
    }
  }

  .img-fluid {
    border-radius: 12px;
    width: 100%;
    object-fit: cover;
    @include ipad {
      height: 192px;
    }
  }

  .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .img-fluid {
      @include ipad {
        width: 37.5px;
        height: 37.5px;
      }
    }
  }
}

.matterport-logo {
  position: absolute;
  bottom: 50px;
  left: calc(50% - 115px);
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    color: $white;
    font-weight: 700;
    font-size: 12px;
  }
}

.matterport-container {
  padding: 27px 0 0;
  max-width: unset;
  .row {
    margin: 0 !important;
    .col-12 {
      padding: 0;
      .img-fluid {
        border-radius: 12px;
      }
    }
  }
}

/******  OPEN HOUSE SEC ******/
.open-house-sec {
  background-color: $ghost-white;
  padding: 45px 0;

  .open-house-inner {
    max-width: 400px;
    width: 100%;
    margin: 35px auto 0;

    ul {
      margin: 0 0 35px 0;

      li {
        display: flex;
        align-items: center;
        font-size: $font16;
        color: $secondary_color;
        margin-bottom: 15px;
        @include Montserrat(700);

        img {
          margin-right: 10px;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

/******  GALLERY SEC ******/
.gallery-sec {
  padding: 27px 0 40px 0;
  background-color: $ghost-white;

  .row {
    margin: 0 !important;
    .col-12 {
      padding: 0;
    }
  }

  .gallery-inner {
    margin: 50px 0 0 0;

    .gallery-item {
      cursor: pointer;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -2.5px 35px;
      justify-content: center;
      @include phone {
        justify-content: unset;
      }

      li {
        width: 25%;
        padding: 1.25px 2.5px;

        img {
          width: 100%;
          border-radius: 8px;
        }
      }
    }
  }

  .load-more-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .load-more-btn {
      height: 46px;
      width: 249px;
      border-radius: 8px;
      padding: 14px 48px 14px 48px;
      background-color: $primary_color;
      border: none;
      font-size: 15px !important;
      font-weight: 600;
      color: $white;
    }
  }
}

.lg-backdrop {
  background-color: rgba($black, 0.8);
}

.swiper-wrapper {
  .image-modal {
    width: 80vw;
    height: 60vh;
  }
}

.image_modal_swiper {
  width: 60vw;
  border-radius: $primary_border_radius;

  @media (max-width: 392px) {
    width: 69vw;
  }
  @media (max-width: 377px) {
    width: 71vw;
  }
  @media (max-width: 370px) {
    width: 68vw;
  }
  @include phone {
    width: 90vw !important;
  }
}

@include desktop {
  .our_work_content {
    .image_modal_swiper {
      width: 69.5vw;
    }

    .slick-next {
      right: 8vw;
    }

    .slick-prev {
      left: 8vw;
    }
  }
}

.slick-initialized {
  display: flex;
  width: 100vw;
  height: 90vh;
  align-items: center;
  justify-content: center;
  @media (min-width: 1200px) {
    width: 100vw;
  }
  @include phone {
    height: 100vh;
  }
}

.close_btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
  @include phone {
    position: absolute;
    top: 29vh;
  }
}

.vertical_dimension_lightbox {
  .close_btn_container {
    @include phone {
      position: absolute;
      top: 5vh;
    }
  }
}

.close-btn_section {
  margin: auto;
  cursor: pointer;
  z-index: 9999999999;
  top: 10px;
}

.slick-prev {
  left: 1vw;
  z-index: 2;
  @media (min-width: 1200px) {
    left: 1vw;
  }
}

.slick-next {
  right: 1vw;
  z-index: 2;
  @media (min-width: 1200px) {
    right: 1vw;
  }
}

.gallery_modal {
  .close-btn_section {
    cursor: pointer;
    z-index: 9999999999;
    top: 10px;
  }
}

.modal_item {
  width: unset !important;
  position: relative;
}

.slick-slide:not(.slick-active) {
  height: 80%;
  > div {
    > .modal_item {
      height: 80%;

      > img {
        height: 100%;
      }
    }
  }
  .modal_absolute_layer {
    position: absolute;
    background: rgba($black, 0.5);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: $primary_border_radius;
  }
}

.slick-slide > div {
  display: grid;
  place-items: center;
  margin: auto;
  padding: 0 20px;
  @include phone {
    padding: 0 4px;
  }
}

/******  PROPERTY DETAIL SEC ******/
.property-detail-sec {
  padding: 40px 0;

  .highlights-ul {
    display: flex;
    flex-wrap: wrap;

    .highlight-item {
      max-width: 100%;
      min-width: 50%;
    }
  }

  .text-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: $font40;
      color: $secondary_color;
    }

    h3 {
      font-size: $font18;
      color: $black;
      margin: 30px 0 10px;
      @include Montserrat(700);
    }

    p {
      line-height: 17px;
      font-size: $font14;
      color: $black;
      @include Montserrat(500);
      margin: 0 0 30px 0;
      max-width: 570px;
      width: 100%;
    }

    h4 {
      font-size: $font18;
      color: $black;
      @include Montserrat(700);
      margin: 0 0 13px 0;
    }

    .media {
      display: flex;
    }

    ul {
      margin-right: 54px;

      li {
        color: $black;
        font-size: $font14;
        position: relative;
        padding: 0 0 0 30px;
        @include Montserrat(500);
        margin: 0 0 16px 0;

        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          height: 3px;
          width: 20px;
          background-color: $secondary_color;
        }
      }
    }
  }

  .container {
    padding-left: 0;
    padding-right: 0;

    .row {
      margin: 0 30px !important;
      display: flex;
      justify-content: space-between;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .image-holder {
    width: unset !important;
    height: 100%;
  }

  .img-fluid {
    object-fit: unset !important;
    border-radius: 12px;
    height: unset !important;
  }
}

/******  NEIGHBORHOOD INFO SEC ******/
.neighborhood-info-sec {
  padding: 0;

  .container-fluid {
    .row {
      margin: 0 !important;
    }
  }

  .text-box {
    background-color: $secondary_color;
    padding: 90px 58px;

    h2 {
      color: $white;
    }

    h3 {
      color: $white;
    }

    h4 {
      color: $white;
    }

    p {
      color: $white;
    }

    ul {
      li {
        color: $white;

        &::after {
          background-color: $white;
        }
      }
    }
  }

  .image-holder {
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.highlights-ul {
  width: 100%;
}

/****** FLOOR PLANS SEC *****/
.floor-plan-sec {
  padding: 45px 0 40px;
  background-color: $white;
}
.floor-plans-sec {
  padding: 45px 0 40px;

  .floor-plan-inner {
    margin: 50px 0 0 0;

    .image-holder {
      margin: 30px;
    }
  }
}

.floor-plans-ul {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 0 !important;
}

.floor-plan-item {
  width: unset !important;
  min-width: 25%;
  max-width: 100%;
  cursor: pointer;

  > div {
    display: flex;
    justify-content: center;
  }
}

.floor-plan-img {
  object-fit: cover;
  height: 60vh;

  @media (max-width: 1440px) {
    height: auto;
    max-width: 626px;
  }

  @include desktop {
    width: 41vw !important;
    height: auto !important;
  }
}

.floor_plans_header {
  display: flex;
  justify-content: center;
  width: 100%;
}

.plans_header_ul {
  min-width: 300px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap !important;
  overflow-x: auto;
  &.centered_heading {
    justify-content: center;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.plan_header_item {
  font-family: Poppins;
  cursor: pointer;
  border-bottom: 1px solid $secondary_color;
  font-size: 16px;
  font-weight: 600;
  color: #07364b4d;
  width: unset !important;
  padding: 10px 35px !important;
  white-space: nowrap;
}

.plan_header_item_active {
  border-bottom: 3px solid $secondary_color;
  font-weight: 700;
  color: $secondary_color;
}

/****** TESTIMONIAL SEC *****/
.testimonial-sec {
  background-color: $ghost-white;
  padding: 35px 0;

  .agent_image_pw_section {
    width: 270px;
    height: 272px;
    @include desktop {
      height: 272px !important;
    }
  }

  .container {
    max-width: unset;

    .row {
      width: 100%;
      margin-left: 0 !important;
      display: flex;
      flex-wrap: wrap;
    }
    .row_centered {
      justify-content: center;
      .image-holder {
        display: flex;
        justify-content: center;
      }
      .agent-item {
        margin-right: unset;
        padding: 0;
      }
      .agent_image_pw_section {
        object-fit: cover;
        @include phone {
          height: 360px !important;
        }
      }
    }
  }

  .agent-item {
    margin-right: 20px;

    @media (min-width: 1200px) {
      margin-right: 0;
      padding: 4px;
    }
  }

  .testimonial-inner {
    margin: 10px 0 0 0;
  }
}

.agent-item {
  max-width: 100%;
  min-width: 40%;
  width: unset !important;
}

/****** AGENT INFO SEC *****/

.agent-info-text {
  margin-left: 5px;
  max-width: 300px;
}

.disable_click {
  margin: 0 0 0 5px !important;
  font-weight: 500 !important;
}

/****** FOOTER SEC *****/

.footer {
  max-width: 100%;
}

.footer-inner-container {
  max-width: 100%;
  padding: 0;

  .row {
    margin: 0 57px;

    @media (max-width: 767px) {
      margin: 0 16px !important;
      * {
        font-size: 11px !important;
        font-weight: 500;
        padding: 0;
      }
    }
  }
}

@include phone {
  .header_cover_image {
    height: 690px;
    object-fit: cover;
  }
  .head_cover_img_safari {
    height: 75vh !important;
  }
}

@import 'components.scss/_responsive.scss';
