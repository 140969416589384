@import '../../../mixins.scss';

.floor_section {
  padding-left: calc(1px + 58px);

  @include not {
    padding-left: 70px;
  }

  @include phone {
    width: 99%;
  }

  @media (max-width: 1400px) {
    margin-top: 0;
  }
  @include ipad {
    padding-left: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: unset;
    padding-top: 20px;
    margin-top: 0;
  }

  .before_checkbox_section {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .before_checkbox_section input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    right: 10px;
    height: 17px;
    width: 17px;
    background-color: transparent;
    border: 1.2px solid #ffffff;
    @include ipad {
      height: 13px !important;
      width: 13px !important;
    }
  }

  .before_checkbox_section input:checked + .checkmark {
    background-size: 60%;
    content: '';
    background-image: url('../feature-sheet/elements/checkmark.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-color: #ffffff;
  }

  .show_checkbox {
    display: block;
  }

  .hide_checkbox {
    display: none;
  }

  .floor_subtitle {
    display: none;
    @include ipad {
      display: block;
      font-style: normal;
      font-weight: normal;
      text-align: center;
      margin-bottom: 6px;
    }
  }

  .floor_item {
    width: 100%;

    .size_mob {
      display: none;

      @include ipad {
        display: block;
        font-family: NunitoSans-Regular;
        display: block;
        font-style: normal;
        font-size: 10px;
        line-height: 14px;
        color: #414141;
        margin-left: 15px;
        margin-top: 40px;
      }
    }

    .size_desk {
      font-family: NunitoSans-Regular;
      display: block;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
      color: #414141;
      margin-bottom: 15px;
      @include ipad {
        display: none;
      }
    }
  }

  .floor_text {
    display: flex;
    font-family: NunitoSans-Regular;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #393535;
    overflow: hidden;
    height: auto;
    max-width: 500px;

    @include ipad {
      font-size: 12px;
      line-height: 16px;
      width: 80%;
      text-align: center;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .images_section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      height: 2px;
      width: 4px;
      border: none;
      position: relative;
      background-color: #e6e6e6;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 6px #bbbaba;
      background-color: #bbbaba;
    }

    .image_section_item {
      position: relative;
      width: 332px;
      height: 220px;

      @include phone {
        width: 170px;
        height: 113px;
      }

      &.image_checked {
        .hover_section {
          .absolute_gradient {
            height: 20%;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.4) 100%
            );
          }
        }
      }

      @media (max-width: 1200px) {
        padding-top: unset;
      }

      > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include phone {
          align-items: center;
          min-width: 170.6px;
          min-height: 113px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: $primary_border_radius_website;

        @media (max-width: 1100px) {
          max-width: 300px;
          height: unset;
          max-height: 150px;
        }

        @include phone {
          max-width: unset;
          width: 170.6px;
          height: 113px;
        }
      }

      .img_type {
        font-family: NunitoSans-Regular;
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
        color: #1a1a1a;
        @include ipad {
          font-size: 12px;
          line-height: 16px;
          width: 100%;
          text-align: center;
        }
      }

      .active {
        position: absolute;
        width: 80%;
        top: 10%;
        left: 10%;
        right: 10%;
        bottom: 10%;
      }

      .hover_section {
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0px;
        right: 0;
        bottom: 0;
        border-radius: 5px;
        max-width: unset;

        @media (max-width: 1200px) {
          top: 0;
          right: 0 !important;
        }
        @include phone {
          top: 0px;
          left: 0px;
          right: 9px !important;
          bottom: 5px;
        }

        .checkmark_checkbox {
          position: absolute;
          z-index: 99;
        }

        .hide_checkbox {
          display: none;
        }

        .show_checkbox {
          display: block;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.5);

          img {
            width: 20%;
            display: block;
          }

          @include phone {
            img {
              display: none;
            }
          }

          .hide_checkbox {
            display: block;
            @include phone {
              display: none;
            }
          }
        }

        img {
          display: none;
          position: absolute;
          z-index: 99;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 43px;
          height: 43px;

          @include ipad {
            width: 1px;
          }
        }

        .before_checkbox_section {
          position: absolute;
          z-index: 99;
          right: 0px;
          bottom: 10px;
          @include phone {
            bottom: 0;
          }
        }
      }
    }
  }

  h2 {
    font-family: 'NunitoSans-Bold';
    font-style: normal;
    font-size: 2.5rem;
    line-height: 3.4rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 15px;
    @include ipad {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 0;
    }
  }

  h3 {
    font-family: 'NunitoSans-Bold';
    font-style: normal;
    font-size: 18px;
    line-height: 24.55px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 10px;
    @include ipad {
      margin-left: 15px;
      font-size: 15px;
      line-height: 20.46px;
      margin-top: 5px;
    }
  }

  .floor_button_section {
    .select_item {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-family: NunitoSans-Regular;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
      margin-top: 10px;
      color: #414141;
      position: absolute;
      right: 17.6rem;
      @include ipad {
        margin-left: 15px;
        justify-content: flex-start;
        margin-top: 5px;
        font-size: 10px;
        line-height: 14px;
        left: 0;
      }
      @include phone {
        width: 90%;
      }
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 45px;
    padding-right: 2.4rem;
    @include ipad {
      margin-top: 5px;
    }

    @include ipad {
      justify-content: flex-start;
    }

    > button {
      width: 150px;
      height: 40px;
      font-size: 12px;
      line-height: 16px;
      outline: none;
      border: none;
      font-family: NunitoSans-Regular;
      transition: 0.3s linear;
      cursor: pointer;
      border-radius: 5px;

      @include ipad {
        width: 150px;
        font-size: 12px;
        line-height: 16px;
        margin-top: 10px;
        height: 35px;
      }

      &:nth-child(2) {
        background-color: #000000 !important;
        padding-left: 0 !important;
        margin-left: 15px;
        color: #ffffff;
        @include ipad {
          display: none;
        }

        &:hover {
          color: $light-purple !important;
        }
      }

      &:nth-child(1) {
        margin-left: 15px;
        border: 1px solid #000000;
        color: #000000;
        background-color: transparent;
        position: relative;

        &:hover {
          background-color: #000000;
          color: #ffffff;

          &::before {
            fill: #ffffff;
          }
        }
      }
    }

    .download_btn_wrapper {
      &:hover {
        .dowload_section {
          display: block;
        }

        .download_btn {
          color: $light-purple !important;
          background-color: #000000;
          border-radius: 5px;

          &:hover {
            color: $light-purple;
            background: black !important;
          }

          > span {
            font-size: 16px;
          }
        }

        .download_btn::before {
          content: url('../images/arrowUp.svg');
          position: absolute;
          right: 10px;
          width: 10px;
          color: $light-purple !important;
          border-radius: 5px;
          @include ipad {
            width: 10px;
            right: 9px;
          }
          @include phone {
            right: 9px;
          }
        }
      }

      .download_btn {
        width: 150px;
        height: 40px;
        font-size: 12px;
        line-height: 16px;
        outline: none;
        border: none;
        font-family: NunitoSans-Regular;
        transition: 0.3s linear;
        cursor: pointer;
        position: relative;
        background-color: $light-purple !important;
        color: #000000;
        padding-left: 0 !important;
        margin-left: 15px;
        display: flex;
        justify-content: flex-end;
        padding-right: 21px;
        align-items: center;
        border-radius: 5px;

        &:hover {
          color: $light-purple;
          background: black !important;
        }

        > span {
          font-size: 16px;
        }

        @include ipad {
          margin-top: 10px;
          height: 35px;
          width: 150px;
          margin-left: 10px;
          padding-right: 3.5rem;
          font-size: 12px;
          line-height: 16px;
        }

        .liner_border {
          border-right: 1px solid $dark-purple !important;
          width: 1px;
          height: 80%;
          margin: 0 6px 0 20px;
          @include ipad {
            margin: 0 10px 0 15px;
          }
          @include phone {
            margin: 0 20px 0 15px;
          }
        }

        &::before {
          content: url('../images/arrowDown.svg');
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          @include ipad {
            width: 10px;
            right: 9px;
          }
          @include phone {
            right: 10px;
          }
        }
      }
    }

    .download_btn.download_floor_plan_btn_disable {
      background-color: #cccccc;

      &:hover {
        background-color: #cccccc;
        color: #000000;
        cursor: not-allowed;
      }
    }

    .download_options.download_options_hide {
      display: none !important;
    }

    .dowload_section {
      position: absolute;
      margin-left: 15px;
      width: 150px;
      display: flex;
      background: #ffffff;
      color: #000000;
      z-index: 99;
      display: none;
      border-radius: 0px 0px 5px 5px;

      @include ipad {
        width: 150px;
        font-size: 13px;
        margin-left: 10px;
      }
      @include ipad {
        width: 150px;
      }

      .download_options {
        display: flex;
        flex-direction: column;

        span {
          font-family: NunitoSans-Regular;
          font-style: normal;
          font-size: 11px;
          line-height: 15px;
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;

          &:hover {
            background: #ebebeb;
            border-radius: 0px 0px 5px 5px;
          }

          @include ipad {
            font-size: 11px;
            line-height: 15px;
          }
        }
      }
    }
  }
}

.floor-multi-liner {
  width: 100%;
  display: flex;
  border: 1px solid #e7e7e7;
  margin-top: 4.1rem;
  @include ipad {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.main_info_floor_plan {
  display: flex;
  @include phone {
    flex-direction: column;
    align-items: center;
  }

  .delivery_update_button {
    margin-left: 3.2rem;
    @include phone {
      margin-left: 0;
      padding-top: 5px;
      padding-bottom: 6px;
    }
  }
}

.select_button_floor_plan_section {
  display: block;
  background-color: #000000 !important;
  padding-left: 0 !important;
  margin-left: 15px;
  color: #ffffff !important;
  border-radius: 5px;

  &:hover {
    color: $light-purple !important;
  }
}

.floor_section .checkmark {
  border-radius: 2px;
}
