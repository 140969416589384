@-webkit-keyframes mainPulseAnimation {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(7, 54, 75, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(7, 54, 75, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(7, 54, 75, 0);
  }
}
@keyframes mainPulseAnimation {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(7, 54, 75, 0.6);
    box-shadow: 0 0 0 0 rgba(7, 54, 75, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(7, 54, 75, 0);
    box-shadow: 0 0 0 10px rgba(7, 54, 75, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(7, 54, 75, 0);
    box-shadow: 0 0 0 0 rgba(7, 54, 75, 0);
  }
}
