.our_work_content {
  width: calc(100% - 240px) !important;

  background-color: $white !important;
}

.pdf_modal,
.matterport_modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.matterport_modal {
  flex-direction: column;

  .close_btn_container {
    margin-bottom: 14px;
  }
}

.our_work_content_wrapper {
  padding: 24px 36px 0 36px;

  .section_head_title {
    font-size: $font24;
    font-weight: $fWeight600;

    color: $gray-700;
  }
  .section_head_description {
    margin-bottom: 15px;

    font-size: $font12;
    font-weight: $fWeight400;

    color: $gray-700;
  }
  .items_container {
    display: flex;
    flex-wrap: wrap;
    gap: 12.5px;
    &.spaced_evenly {
      justify-content: space-evenly;
    }
  }
}

.our_work_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .lightbox_video {
    max-height: 90%;
    height: fit-content;
    width: 94%;
    object-fit: cover;

    border-radius: $border_radius_four;
  }
}

.our_work_matterport_container {
  width: 86vw;
  height: 48vw;
  border-radius: $primary_border_radius;
  overflow: hidden;
  .our_work_matterport_iframe {
    width: 100%;
    height: 100%;
  }
}

.reel_modal {
  .lightbox_video {
    object-fit: cover;
    @include desktop {
      height: 75%;
      width: 26%;
    }
    @include phone {
      height: 620px;
      width: 375px;
      max-height: 80% !important;
    }
  }
}

@include ipad {
  .lightbox_video {
    max-height: 80% !important;
  }
}
