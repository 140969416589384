@import './mixins.scss';

.download_zone_container {
  width: 100%;

  * {
    font-family: NunitoSans-Regular !important;
  }

  section {
    .content {
      background: transparent !important;
    }
  }
}

.copy_component {
  justify-content: center !important;
  margin-top: 0;
}

.disabled_touch_events {
  touch-action: none;
}

.content {
  h2 {
    > span {
      font-size: 16px;
      align-self: baseline;
      font-weight: 700;

      @include ipad {
        align-self: center;
      }
    }
  }
}

.btn-wrapper {
  * {
    font-size: 12px !important;
  }
}

.content {
  > .grid-container {
    @media (min-width: 980px) {
      // grid-template-columns: repeat(3, minmax(30%, 1fr)) !important;
    }
  }
}

.relative {
  position: relative;
}

.quantity_text {
  span {
    font-size: 10px;
  }
}

.header_navigation {
  position: fixed !important;
  z-index: 10;
  width: 100%;
  top: 0;
  padding-right: 5.1rem;
  padding-left: 50px;
  transition: 0.5s;
  z-index: 14;
  height: 7.8rem;
  border-bottom: 1px solid transparent;
  height: 66px;

  @media only screen and (max-width: 1100px) {
    justify-content: space-between !important;
    height: 54px;
    padding-right: 0;
    padding-left: 0;
  }

  .black_logo {
    display: none;
  }
  .white_logo {
    width: 182px;
    height: 35px;
  }

  .cart_black {
    display: none;

    @include ipad {
      height: 15px;
    }
  }

  .user-signin {
    cursor: pointer;
    width: 77px;
    height: 38px;
    border: 1px solid #ffffff;
    border-radius: 5px;

    @media (max-width: 1100px) {
      width: 70px;
      height: 30px;
    }

    .userIcon-black {
      display: none;
    }

    .userIcon-white {
      width: 17px;
      height: 17px;
      display: block;
      @media (max-width: 1100px) {
        width: 13px;
        height: 13px;
      }
    }

    .sign-up-button {
      color: #ffffff;
    }
  }

  .white_cart {
    width: 24px;
    height: 20px;
    display: block;

    @media (max-width: 1390px) {
      width: 24px;
      height: 20px;
    }
  }

  .black_phone {
    display: none;
  }

  .navbar-brand {
    padding: 0;
    margin-right: 0;
    margin-left: 50px;

    @media (max-width: 1100px) {
      position: relative;
      margin-left: 0;
    }

    img {
      @media only screen and (max-width: 1100px) {
        width: 130px !important;
        height: 25px !important;
        margin-left: 15px;
      }
    }
  }

  &.pricing,
  &:hover {
    background-color: white;
    z-index: 999;
    height: 65px;
    border-bottom: 0.5px solid #a6a6a6;

    &.header_navigation_modal_mode {
      z-index: 2;
    }

    .new_home_sign_in_icon_wrapper {
      .new_home_sign_up_button {
        .new_home_sign_in_line {
          border: 1px solid #000000;
        }

        @media (max-width: 1100px) {
          display: none;
        }
      }
    }

    .user-signin {
      border: 1px solid #ffffff;
    }

    .new_home_nav_cart_container {
      border: 1px solid #1a1a1a;
    }

    @media (max-width: 1100px) {
      height: 54px;
    }

    .pop {
      i {
        border-color: black !important;
      }
    }

    .name {
      color: black !important;
    }

    svg {
      path {
        fill: black;
      }
    }

    .humburgerblack_wrapper {
      @media (max-width: 1100px) {
        width: 41px;
        height: 32px;
        border: 1px solid #1a1a1a;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
      }

      .humburgermenublack {
        @media only screen and (max-width: 1100px) {
          display: block;
        }
      }
    }

    .mobile-signin-black {
      @media only screen and (max-width: 1100px) {
        display: block;
      }
    }

    .mobile-signin-white {
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }

    .closing-button {
      @include ipad {
        display: block;
        height: 21px;
      }
    }

    .humburgermenuwhite_wrapper {
      display: none;
    }

    .humburgermenuwhite {
      display: none;
    }

    .cart {
      position: absolute;
    }

    .black_phone {
      display: none;
    }

    .white_phone {
      display: none;
    }

    .cart_black {
      display: block;
      width: 24px;
      height: 20px;
      @media (max-width: 1390px) {
        width: 24px;
        height: 20px;
      }

      @include ipad {
        width: 30px;
        height: 21px;
      }
    }

    .cart_white,
    .white_cart {
      display: none;
    }

    .navigation_links {
      color: black !important;
    }

    .nav_cart_container {
      div {
        color: black;
        display: flex;
        align-items: center;

        &:hover {
          color: $light-purple !important;
        }
      }

      @media only screen and (max-width: 1100px) {
        cursor: pointer;
        border: 1px solid #1a1a1a;
        .cart-text {
          @media (max-width: 1100px) {
            display: none;
          }
        }
      }
    }

    .user-signin {
      width: 77px;
      height: 38px;
      border: 1px solid #1a1a1a;
      border-radius: 5px;

      .userIcon-black {
        width: 17px;
        height: 17px;
        display: block;
        @media (max-width: 1100px) {
          width: 13px;
          height: 13px;
        }
      }

      .userIcon-white {
        display: none;
      }

      .sign-up-button {
        color: #000;
      }

      .sign-in-dropdown {
        display: none;
      }

      @media (max-width: 1100px) {
        width: 44px;
        height: 32px;
      }
    }

    .order-tour {
      list-style-type: none;

      .order-btn-wrapper {
        .order-tour-btn {
          background-color: transparent;

          .order-link {
            color: #000000;

            &:hover {
              color: #000000 !important;
            }
          }
        }
      }
    }

    a {
      color: black;
    }

    .nav_phone_number_container {
      div {
        color: black;
      }
    }

    .black_logo {
      display: block;
      width: 182px;
      height: 35px;
    }

    .white_logo {
      display: none;
    }
    ///////////////////////////////////////////black////////////////////////////////////////
    .navigation_cart_container {
      > a {
        .head_phone_icon {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid #1a1a1a;
          transition: all 0.5s;
          overflow: hidden;
          background: #ffffff;

          > img {
            margin: 0 10px;
            width: 17.5px;
            height: 17.5px;
          }

          &:hover {
            width: 133px;
            justify-content: flex-start;
            transition: all 0.5s;
          }

          .head_phone_number {
            width: 90px;
            white-space: nowrap;
          }

          @media (max-width: 1100px) {
            display: none;
          }
        }
      }

      .new_home_nav_cart_container {
        @media only screen and (max-width: 1100px) {
          position: absolute;
          right: 51px;
          margin-right: 0;
        }
      }

      .humburgerblack_wrapper {
        @media only screen and (max-width: 1100px) {
          position: absolute;
          right: 0;
          margin-right: 0;
        }
      }

      .person_id {
        @media only screen and (max-width: 1100px) {
          position: absolute;
          left: -15px;
          margin-left: 15px;
        }
      }

      @media (max-width: 1100px) {
        position: relative;
        width: 148px;
        height: 50px;
      }
    }

    &.small_nav {
      height: 66px;
    }
  }

  &.pricing_nav {
    border-bottom: 1px solid #ebebeb;
    border: 1px solid #ebebeb;
    background-color: white;
    z-index: 999;

    ​ .humburgermenublack {
      @media only screen and (max-width: 1100px) {
        display: block;
      }
    }

    .humburgermenuwhite_wrapper {
      display: none;
    }

    .humburgermenuwhite {
      display: none;
    }

    .black_phone {
      display: block;
    }

    .white_phone {
      display: none;
    }

    .cart_black {
      display: block;
      background-color: yellow;
    }

    .white_cart {
      display: none;
    }

    .navigation_links {
      color: black !important;
    }

    .nav_cart_container {
      div {
        color: black;

        &:hover {
          color: $light-purple !important;
        }
      }

      @media only screen and (max-width: 1100px) {
        cursor: pointer;
      }
    }

    a {
      color: black;
    }

    .nav_phone_number_container {
      div {
        color: black;
      }
    }

    .black_logo {
      display: block;
    }

    .white_logo {
      display: none;
    }

    &.small_nav {
      height: 66px;
    }
  }
}

///////////////////////////////////////////////white///////////////////////////////////
.navigation_cart_container {
  display: flex;
  align-items: center;
  margin-right: 9px;

  @include phone {
    margin-right: 0;
  }

  .user-signin {
    display: flex;
    align-items: center;
    justify-content: center;

    .sign-in-dropdown {
      display: none;
      animation-name: drop_open;
      animation-duration: 0.5s;
    }

    &:hover {
      .sign-in-dropdown {
        width: 73px;
        height: 100px;
        display: block;
        position: absolute;
        top: 51px;
      }

      @keyframes drop_open {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    @media (max-width: 1100px) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 32px;
    }
  }

  .humburgermenuwhite_wrapper {
    @media (max-width: 1100px) {
      width: 41px;
      height: 32px;
      border: 1px solid #ffffff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
    }

    .humburgermenuwhite {
      height: 15px;
      display: none;
      @media only screen and (max-width: 1100px) {
        display: block;
      }
    }
  }

  .closing_button_wtapper {
    @media (max-width: 1100px) {
      width: 41px;
      height: 32px;
      border: 1px solid #1a1a1a;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
    }
  }

  .humburgermenublack {
    display: none;
    margin-right: 0;
    height: 15px;
  }

  .mobile-signin-black {
    display: none;
  }

  .person_id {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      .popMenu {
        display: flex !important;
      }
    }

    .icon {
      height: 3rem;
      width: 3rem;
      @include ipad {
        height: 30px;
        width: 30px;
      }

      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .name {
      padding-left: 1rem;
      font-size: 1.3rem;
      line-height: 1.8rem;
      color: white;
      font-family: NunitoSans-SemiBold, sans-serif;
      cursor: pointer;
      @include ipad {
        padding-left: 10px;
        font-size: 13px;
        line-height: 18px;
      }
    }

    .sign-out-span,
    .my-account-span,
    .settings-span {
      margin-left: 0.8rem;
      font-size: 1.4rem;
      @include ipad {
        margin-left: 8px;
        font-size: 11px;
      }
    }

    .sign-out-span {
      color: rgba(26, 26, 26, 0.6);
    }

    .my-account-span,
    .settings-span {
      color: #1a1a1a;
    }

    .pop,
    .popUsers {
      margin-left: 1rem;
      position: relative;
      height: 1.8rem;
      display: flex;
      align-items: center;
      @include ipad {
        height: 18px;
        margin-left: 10px;
      }

      > i {
        border: solid white;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg) translateY(-3px);
        cursor: pointer;
      }

      .popMenu {
        right: -2rem;
        bottom: -3rem;
        height: 10.5rem;
        width: 21rem;
        box-shadow: 0px 0px 1.5rem rgba(130, 130, 130, 0.25);
        transform: translateY(100%);
        position: absolute;
        display: none;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        border: 1px solid #ebebeb;
        @include ipad {
          right: -20px;
          bottom: -15px;
          height: 105px;
          width: 120px;
          box-shadow: 0px 0px 15px rgba(130, 130, 130, 0.25);
        }

        &:hover {
          display: flex;
        }

        > div,
        > a {
          cursor: pointer;
          padding-left: 1.5rem;
          height: 2.8rem;
          display: flex;
          align-items: center;
          @include ipad {
            padding-left: 15px;
            height: 28px;
          }
        }
      }
    }
  }

  .new_home_nav_cart_container {
    @media only screen and (max-width: 1100px) {
      position: absolute;
      right: 51px;
      margin-right: 0;
    }
  }

  .humburgermenuwhite_wrapper {
    @media only screen and (max-width: 1100px) {
      position: absolute;
      right: 0;
      margin-right: 0;
    }
  }

  .person_id {
    @media only screen and (max-width: 1100px) {
      position: absolute;
      left: -15px;
      margin-left: 15px;
    }
  }

  @media (max-width: 1100px) {
    position: relative;
    width: 148px;
    height: 50px;
  }
}

.navbar-collapse.nav_list_container {
  justify-content: center;

  .navbar-nav.more {
    @media only screen and (min-width: 993px) and (max-width: 1600.8px) {
      font-size: 10px !important;
      .navigation_links {
        svg {
          right: -4px !important;
        }
      }
    }
  }

  .navbar-nav.new_not_login {
    max-width: 455px;
  }

  @media only screen and (max-width: 1100px) {
    background-color: white;
    color: black;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    z-index: 999999999;
    top: 0;
    left: 0;
    transition: 0.5s;
    transform: translate(100%);
    display: flex;
    overflow: auto;
    transition: 0.5s;
    .navigation_links {
      display: flex;

      ​ > img {
        object-fit: contain;
      }
    }
    &.show_menu {
      transform: translate(0);

      .navigation_links {
        transform: translate(0) !important;
      }
    }
    .navbar-nav {
      width: 100%;
      height: calc(100% - 100px);
      margin-top: 25px;
      border-top: 0.5px solid #a6a6a6;

      .sign_in {
        margin: 20px auto 0;
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: #000000;
        background-color: $light-purple !important;
        height: 30px;
        width: calc(100% - 30px);
        cursor: pointer;
        border: none;
        font-family: NunitoSans-SemiBold, sans-serif;
        font-size: 12px;
        text-decoration: none;
      }

      @media (max-width: 1100px) {
        flex-direction: column;
      }
    }
  }
  .navbar-nav {
    width: 535px;
    justify-content: space-between;

    @media (max-width: 1100px) {
      min-width: 100%;
      justify-content: flex-start;
    }

    .nav-item {
      .nav-link {
        @media (max-width: 1100px) {
          padding: 23px 19px !important;
        }
      }
    }
  }

  .new_not_login {
    width: 455px;
  }
}

#main_navigation {
  @media (max-width: 1200px) {
    margin-right: 80px;
  }

  .sign_in {
    display: none;
  }

  .nav-item {
    @media only screen and (max-width: 1100px) {
      &:first-of-type {
        > a {
          transition: 0.5s;
          border-top: 1px solid #ebebeb;
        }
      }
      &:nth-child(2) {
        > a {
          transition: 0.8s;
        }
      }
      &:nth-child(3) {
        > span {
          transition: 1.2s;
        }
      }
    }

    .nav-link {
      padding: 0;
    }
  }

  .navigation_links {
    font-family: NunitoSans-SemiBold, sans-serif;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 1250px) {
    }

    @media (max-width: 1100px) {
      font-size: 16px;
    }

    svg {
      display: none;
      @media (max-width: 1100px) {
        transform: translateY(-50%);
        max-height: 9px;
        width: 16px;
        margin-left: auto;
        position: absolute;
        right: 30px;
        display: block;
        path {
          fill: black !important;
        }
      }
    }

    &:hover {
      color: $light-purple !important;

      svg {
        path {
          fill: $light-purple !important;
        }
      }

      > .pop {
        display: flex;

        @media (max-width: 1100px) {
          display: none;
        }
      }

      > .services-pop {
        visibility: visible;
        display: grid;
        @media (max-width: 1100px) {
          display: none;
        }

        .services_pop_bottom {
          width: 100%;
          height: 25px;
          background: transparent;
          position: absolute;
          top: -25px;
        }
      }

      .short-highlighted-border_wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        .short-highlighted-border {
          display: block;
          position: absolute;
          top: 56px;
          border: 2px solid #000;
          width: 12.4rem;
          height: 0.3rem;
          border-radius: 1px;
          top: 18px;
          left: -1rem;

          @media (max-width: 1100px) {
            display: none;
          }
        }
      }
    }

    &.hide {
      .pop {
        display: none !important;
      }
    }

    &.open {
      border-bottom: transparent;

      svg {
        transform: scaleY(-1);
      }

      > .pop {
        display: flex !important;
      }
    }

    .pop {
      background: #ffffff;
      border: 1px solid #ebebeb;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      display: flex;
      flex-direction: column;
      padding: 5px 0;
      display: none;

      @media (max-width: 1100px) {
        border: unset;
        padding-left: 13px;
        position: relative;
        float: left;
        transform: translateY(30px) translateX(-78px);

        > span {
          line-height: 35px !important;

          &:hover {
            background-color: unset !important;
          }
        }
      }

      > span {
        font-size: 1.1rem;
        line-height: 2.5rem;
        color: #1a1a1a;
        padding: 0 1.5rem;
        white-space: nowrap;
        cursor: pointer;
        @media (max-width: 1100px) {
          font-size: 11px;
          line-height: 25px;
          padding: 0 15px;
        }

        &:hover {
          background-color: $light-purple !important;
        }
      }
    }

    .user_pop {
      width: 162px !important;
      border-radius: 5px;

      > span {
        font-family: 'NunitoSans-Regular', Sans-Serif;
        font-size: 13px;
        line-height: 18px;
        color: #1a1a1a;
      }
    }

    .services-pop {
      position: absolute;
      background: #ffffff;
      display: grid;
      grid-template-columns: auto auto auto;
      padding-left: 35px;
      padding-top: 35px;
      padding-bottom: 60px;
      height: 258px;
      border-radius: 0px 0px 15px 15px;
      display: none;
      top: 64px;
      width: 920px;
      z-index: 20;
      transform: unset;
      visibility: visible;
      animation-duration: 0.5s;
      animation-name: servicesPopUp;

      @media (max-width: 1100px) {
        position: relative;
        height: unset;
        grid-template-columns: none;
        width: unset;
        transform: translateY(-32px) translateX(-82px);
        margin-right: 100%;
      }

      .services-dropdown {
        width: max-content;
        height: 32px;
        display: flex;
        align-items: center;
        padding-right: 3.8rem;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        margin-bottom: 12px;

        @media (max-width: 1100px) {
          font-size: 14px;
        }

        .service-icons {
          max-width: 20px;
          max-height: 20px;
          margin-right: 1rem;

          @media (max-width: 1100px) {
            width: 19px;
            height: 15px;
            padding-right: 5px;
          }
        }

        &:hover {
          background-color: #ffe241;
          color: #000000;
          border-radius: 5px;
          transition: all 0.5s;
        }
      }
      @keyframes servicesPopUp {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    @media only screen and (max-width: 1100px) {
      color: black;
      transform: translate(100%);
      border-bottom: 1px solid #a6a6a6;
    }

    .services_arrow_down {
      margin-left: 2px;

      @media (max-width: 1100px) {
        display: none;
      }
    }

    &.navigation_links_users {
      position: relative;
    }
  }

  .mobile_header {
    position: absolute;
    top: 0;
    padding-top: 17px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 1100px) {
    top: 50px;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 160vw;
  }
}

.nav_phone_number_container {
  display: flex;
  margin-right: 20px;
  @media only screen and (max-width: 1100px) {
    margin-right: 15px;
  }

  img {
    @media only screen and (max-width: 1100px) {
      display: none;
    }
  }

  a {
    font-family: NunitoSans-SemiBold, sans-serif;
    font-size: 11px;
    color: #ffffff;
    text-decoration: none !important;

    &:hover {
      color: $light-purple !important;
    }

    @media only screen and (max-width: 1100px) {
      display: none;
    }
  }
}

.nav_cart_container {
  display: flex;
  margin: 0 15px;
  @media only screen and (max-width: 1100px) {
    margin-right: 15px;
  }

  img {
    @media only screen and (max-width: 1100px) {
      margin-right: 0;
      width: 30px;
      height: 21px;
    }
  }

  div {
    font-family: NunitoSans-SemiBold, sans-serif;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;

    @media (max-width: 1280px) {
      font-size: 12px;
    }

    &:hover {
      color: $light-purple !important;
    }

    @media only screen and (max-width: 1100px) {
      display: none;
    }
  }
}

.order-tour {
  list-style-type: none;

  .order-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    text-decoration: none;
    margin-bottom: 1px;
    padding-left: 0.5rem;
    width: 90px;
    height: 38px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
    background-color: $light-purple !important;
    margin-left: 15px;

    @media (max-width: 1100px) {
      display: none;
    }

    &:hover {
      transition: all 0.3s;

      .order-tour-btn {
        .order-link {
          color: #000000;
        }
      }
    }

    .order-tour-btn {
      cursor: pointer;
      font-size: 13px;
      border-radius: 5px;
      line-height: 18px;
      font-family: 'NunitoSans-SemiBold', sans-serif;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      .order-link {
        color: #000000;
      }
      @media (max-width: 1200px) {
        width: 100px;
        height: 35px;
      }

      @media (max-width: 1100px) {
        display: none;
      }
    }
  }
}

.sign-up-button {
  display: flex;
  font-family: 'NunitoSans-SemiBold', sans-serif;
  font-size: 14px;
  color: #1a1a1a;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  position: relative;

  @media (max-width: 1280px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.name-white {
  color: #ffffff !important;
}

.bag-navigation {
  position: relative;

  @media (max-width: 1100px) {
    right: 15px;
  }
}

.auth-bag {
  display: none;
  width: 170px;
  min-height: 70px;
  background: white;
  position: absolute;
  left: -10px;
  top: 33px;
  transform: translateX(-50%);
  -webkit-box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.35);

  &.active {
    display: block;
    border-radius: 5px;
  }

  .auth-triangle {
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    position: absolute;
    left: calc(50% - 25px);
    transform: rotate(45deg);
    z-index: 1;
  }

  .bag-content {
    position: relative;
    z-index: 2;

    .bag-table {
      margin: 13px 10px 10px 12px;
      display: block;

      > tr {
        display: flex;
        justify-content: space-between;

        > td {
          font-family: 'NunitoSans-Regular', Sans-Serif;
          font-size: 13px;
          line-height: 18px;
          color: #1a1a1a;
        }
      }
    }

    .title {
      font-family: NunitoSans-Bold;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }

    .price {
      color: #000000;
      font-family: NunitoSans-Regular;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}

.disable-hamburger-icon {
  display: none;
}

.new_home_sign_in_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 57px;

  .new_home_sign_up_button {
    display: flex;
    height: 22px;
    justify-content: space-evenly;
    flex-direction: column;

    .new_home_sign_in_line {
      width: 18px;
      border: 1px solid #ffffff;
      border-radius: 5px;
    }

    @media (max-width: 1100px) {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    width: 24px;
  }
}

.new_home_user_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  @media (max-width: 1100px) {
    width: 24px;
    height: 24px;
  }
}

.new_home_nav_cart_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 38px;
  border: 1px solid #ffffff;
  border-radius: 5px;

  @media (max-width: 1100px) {
    width: 30px;
    height: 30px;
    border: none;
  }

  .new_home_nav_cart_container_dot {
    position: absolute;
    left: 30px;
    bottom: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $light-purple !important;
    border: 1px solid #ffffff;

    .new_home_cart_text {
      font-family: 'NunitoSans-Regular';
      font-size: 10px;
      line-height: 14px;
      color: #1a1a1a;
    }
  }

  @media (max-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    margin-right: 15px;
  }
}

.new_home_user_signin_wrapper {
  display: flex;
  align-items: center;
  height: 65px;
}

.new_home_order_link {
  &:hover {
    color: #000000 !important;
  }
}
.head_phone_icon_hover_black {
  display: none;
}
.head_phone_icon_hover_white {
  display: none;
}

.head_phone_icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 38px;
  height: 38px;
  transition: all 0.5s;
  overflow: hidden;

  > img {
    margin: 0 10px;
    width: 17.5px;
    height: 17.5px;
  }

  &:hover {
    width: 133px;
    justify-content: flex-start;
    transition: all 0.5s;
  }

  .head_phone_number {
    font-family: 'NunitoSans-SemiBold', sans-serif;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    width: 90px;
    white-space: nowrap;
  }

  @media (max-width: 1100px) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .head_phone_icon_black,
  .head_phone_icon_white,
  .head_phone_icon_hover_white {
    width: 30px;
    height: 30px;
  }
}

.navigation_cart_container_mobile {
  display: none;
}

.header_navigation {
  padding: 0 !important;
}

#main_navigation {
  @media (max-width: 1100px) {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.new_header_navigation_opened_menu {
  background: white !important;
}

.gallery_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: $z_index_last_floor;
}

.close-btn_section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: #fff;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  z-index: 9999999999;
  top: 10px;
}
