.invoiceCard {
  .rdt_TableHeader {
    background: none;

    div:first-child {
      font-size: $font18;
      font-weight: $fWeight600;

      color: $primary;
    }
  }

  header {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;

    background: none;

    .searchField {
      position: relative;

      i {
        position: absolute;
        right: 10px;
        top: 7px;
      }

      .search_clear_icon {
        cursor: pointer;
      }
    }

    .table_search_inp {
      border-radius: $border_radius_five;

      &::placeholder {
        font-size: $font14;
      }
    }
  }
}

.billing_container {
  .rdt_Table {
    border: $border_gray_200;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
  }

  .rdt_TableHeadRow {
    height: 40px !important;

    background-color: $gray-50 !important;
    box-shadow: inset 0px -1px 0px #f1f1f1;
  }

  .rdt_TableRow {
    min-height: 60px !important;
    height: auto !important;

    border: $border_gray_200;

    a {
      white-space: break-spaces;
    }
  }

  .rdt_TableCol {
    @include phone {
      min-width: 200px !important;
    }
  }

  .rdt_TableCell {
    min-width: 200px !important;

    @media (max-width: 1400px) {
      min-width: 120px !important;
    }

    @include phone {
      min-width: 200px !important;
    }
  }

  .download_invoice {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
  }

  .download_invoice_btn {
    height: 32px;
    display: flex;
    column-gap: 10px;
    padding: 10px;

    border-radius: $primary_border_radius;

    font-size: $font12;
    font-weight: $fWeight400;

    @media (max-width: 1440px) {
      font-size: $font12 !important;
      column-gap: 5px;
      padding: 5px 10px;
      height: 25px !important;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}
