@import '../../../mixins.scss';

.property_title {
  font-family: 'NunitoSans-Bold', sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 3.4rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  margin-left: calc(1px + 58px);
  padding-top: 30px;

  @include not {
    margin-left: 70px;
  }

  @include ipad {
    margin-left: unset !important;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 25px;
  }
  @include phone {
    padding-top: 0 !important;
  }
}

.quantity_text {
  font-size: 12px;
  * {
    font-size: 12px !important;
  }
}

.property_web {
  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-left: 55px;
    padding-top: 1.7rem;
    padding-right: 8.7rem;
    margin-right: 0;
    margin-top: unset;

    @include ipad {
      width: 100%;
      margin-top: 15px;
      margin-left: 0;
      padding: 0 15px;
    }
    @include phone {
      margin-top: unset;
    }

    .property_liner {
      display: none;
      @include ipad {
        display: block;
        width: 92.2%;
        margin-left: 12px;
        border: 1px solid #e7e7e7;
        margin-bottom: 15px;
        margin-top: 15px;
      }
    }

    .main_sec_liner {
      display: block;
      width: 92.2%;
      margin-left: 12px;
      border: 1px solid #e7e7e7;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .website_content {
      width: 100%;
      @include not() {
        width: 100%;
      }
      @include phone() {
        width: 100%;
      }

      h1 {
        font-family: 'NunitoSans-SemiBold';
        font-size: 17.6px;
        margin: 0 0 9px;
        @include ipad {
          max-width: 193px;
          margin: 0 0 5px;
        }
        @include ipad() {
          font-size: 15px;
          line-height: 20px;
          max-width: unset;
        }
      }

      span {
        font-family: NunitoSans-Regular;
        font-style: normal;
        font-weight: normal;
        color: #393535;
        font-size: 12px;
        line-height: 16px;
        @include ipad {
          font-size: 12px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }

    .website_section {
      margin-bottom: 1.5rem;
      @include ipad {
        margin-bottom: 0;
      }

      > .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        @include ipad {
          margin-top: 0;
          justify-content: flex-start;
        }

        .btn_buton {
          width: 150px;
          height: 40px;
          font-size: 12px;
          outline: none;
          font-family: NunitoSans-Regular;
          text-transform: uppercase;
          cursor: pointer;
          line-height: 1.6rem;
          border-radius: 5px;
          color: #000000;

          @include ipad {
            font-size: 12px;
            margin-top: 10px;
            width: 150px;
            height: 35px;
          }

          .liner_border {
            border-right: 1px solid #bfbfbf;
            width: 1px;
            height: 90%;
            margin: 0 10px;
          }

          &:nth-child(2) {
            border: none;
            background-color: $light-purple !important;
            color: #000000 !important;
            padding-left: 0 !important;
            margin-left: 15px;
            @include ipad {
              margin-left: 10px;
            }

            &:hover {
              color: $light-purple !important;
              background-color: #000000 !important;
            }

            &::before {
              border: none;
              content: unset;
            }
          }

          &:nth-child(1) {
            margin-left: 15px;
            box-sizing: border-box;
            border-color: #000000;
            background-color: transparent;
            position: relative;
            padding-left: 24px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @include ipad() {
              padding-left: 20px;
              margin-left: 0;
            }

            &::before {
              content: '';
              position: absolute;
              background-image: url('../../../../../assets/icons/copy-light.svg');
              background-size: cover;
              width: 16px;
              height: 16px;
              left: 10px;
              top: 50%;
              transform: translateY(-60%);

              @include not() {
                left: 10px;
              }
              @include ipad() {
                left: 10px;
                width: 13.94px;
                height: 14px;
              }
            }

            &:hover {
              background-color: #000000 !important;
              color: $light-purple !important;

              &::before {
                // background-image: url('../images/copyHover.svg');
              }

              .liner_border {
                border-color: $dark-purple;
              }
            }
          }
        }

        .btn_buton_customize {
          width: 150px;
          height: 40px;
          font-size: 12px;
          outline: none;
          font-family: NunitoSans-Regular;
          text-transform: uppercase;

          cursor: pointer;
          border-radius: 5px;

          @include ipad() {
            width: 150px;
            height: 35px;
            margin-top: 10px;
            font-size: 12px;
          }

          &:nth-child(2) {
            border: none;
            background-color: $light-purple !important;
            padding-left: 0 !important;
            margin-left: 20px;
            color: #000000 !important;

            &:hover {
              color: $light-purple !important;
              background-color: #000000 !important;
            }

            &::before {
              border: none;
              content: unset;
            }

            @include ipad {
              margin-left: 10px;
              height: 35px;
            }
          }

          &:nth-child(1) {
            margin-left: 15px;
            box-sizing: border-box;
            background-color: transparent;
            position: relative;
            border-color: #000000;
            box-sizing: border-box;
            color: #000000;

            @include ipad {
              margin-left: 0;
              height: 35px;
            }

            &:hover {
              background-color: #000000;
              color: $light-purple;

              &::before {
                // background-image: url('../images/copyHover.svg');
              }

              .liner_border {
                border-color: $dark-purple;
              }
            }
          }
        }
      }

      > .btn_wrapper_custom_url {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;

        @include ipad {
          justify-content: flex-start;
          margin-top: 15px;
        }

        .copied_btn_custom_url {
          border-radius: 5px;
          background-color: black !important;
          color: #fff !important;

          &:hover {
            color: #fff !important;
          }

          &::before {
            content: '';
            position: absolute;
            background-image: url('../images/copiedURL.svg') !important;
            background-size: cover;
            width: 16px;
            height: 16px;
            left: 12px !important;
            top: 50%;
            transform: translateY(-60%);
          }
        }

        .custom_url_button {
          position: relative;
          border: 0.7px solid rgba(0, 0, 0, 0.3);
          height: 40px;
          width: 398px;
          display: flex;
          align-items: center;
          padding-left: 4.5rem;
          font-size: 12px;
          outline: none;
          font-family: NunitoSans-Regular;
          text-transform: uppercase;
          line-height: 1.6rem;
          border-radius: 5px;

          .url-span {
            text-transform: lowercase;
            font-size: 12px;
            display: inline-block;
            width: fit-content;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            padding-right: 5px;
            @include ipad {
              font-size: 12px;
              line-height: initial;
              padding-left: 6px;
            }
          }

          @include ipad {
            padding-left: unset;
            justify-content: center;

            font-size: 9px;
            width: 150px;
            height: 35px;
          }

          .liner_border {
            border-right: 1px solid #bfbfbf;
            width: 1px;
            height: 90%;
            margin: 0 10px;
            @include ipad {
              display: none;
            }
          }

          &::before {
            content: '';
            position: absolute;
            background-image: url('../images/copy_url.svg');
            background-size: cover;
            width: 22px;
            height: 20px;
            left: 1.7rem;
            top: 0.8rem;
            @include ipad {
              background-image: unset;
              content: unset;
            }
          }
        }
      }
    }
  }
}

.property_subtitle {
  display: none;

  @include phone {
    display: block;
    font-family: NunitoSans-Regular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #00000066;
  }
}

.services {
  &.grey {
    background: #f4f4f4;
  }

  .main {
    display: flex;
    margin-top: 3rem;
    height: unset;
    padding-left: calc(1px + 20px);
    width: 100%;
    padding-top: 0;
    @include not() {
      height: unset;
      justify-content: space-between;
    }
    @media (min-width: 800px) and (max-width: 1123px) {
      height: unset;
    }
    @include ipad() {
      margin-top: 20px;
      padding-left: 0;
      height: unset;
      flex-direction: column;
      align-items: center;
    }

    &.animate {
      animation: fade 1s linear forwards;
    }

    @keyframes fade {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}

.down_zone_main_btn {
  color: #fff !important;
  font-family: Nunito Sans !important;
  * {
    font-family: Nunito Sans !important;
    color: #fff !important;
  }
  &.embed_code_btn {
    width: 167px !important;
    padding-left: 25px !important;
  }
}

.down_zone_matterport_btn {
  margin-left: 0 !important;
  .liner_border {
    margin-left: 10px !important;
  }
}
