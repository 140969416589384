* {
  font-family: $font_family_dashboard_main !important;
}

.tourMain {
  margin-bottom: 24px;
}

.badgeRed {
  background: $danger;
  color: $white;
}

.badgeWarning {
  background: $warning;
  color: $white;
}

.badgeDarkWarning {
  background: $darkWarning;
  color: $white;
}

.badgeOutlineDanger,
.badges-pending {
  border: 1px solid $danger;
  color: $danger;
  background: white;
}

.badgeDanger,
.badges-requested {
  color: $white;
  background: $danger;
}

.badgeDefault {
  background: $light;
  color: $gray-800;
}

.badgeSuccess {
  background: $success;
  color: $white;
}

.pricingMain {
  label {
    font-size: 14px;

    span {
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      color: $gray-700;
    }
  }

  .sizeList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -12px;

    li {
      padding: 4px 12px;
    }
  }
}

.packages {
  margin-bottom: 24px;
  position: relative;

  .serviceCharges {
    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: $gray-700;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 150%;
      color: $gray-700;
    }

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -6px;
      gap: 2px;

      li {
        padding: 0 6px;

        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-size: 12px;
          line-height: 100%;
          font-weight: 500;
          background: $primary;
          border-radius: 12px;
          padding: 5px 10px;
        }
      }
    }
  }
}

.section_disable_container_abbr {
  cursor: default !important;
}

.additional_services_container {
  width: 100%;
}

.services_ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 7px;
}

.mediaBody {
  .name {
    color: #242d35 !important;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
}
.address_container {
  width: 60%;
}

.address_details_container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 30px;
  margin-bottom: 41px;

  .part_title {
    font-size: 14px;
    font-weight: 500;
  }
}

.address_details_editing_container {
  column-gap: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.dates_part_container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .card-detail {
    padding: 16px 16px 32px 16px;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
  }
  .shooting_date_card {
    padding-bottom: 21px;
  }
}

.card_buttons_container {
  display: flex;
  gap: 15px;
}

.edit-btn {
  @include Montserrat(400);
  font-size: $font12;
  line-height: 150%;
  display: inline-flex;
  color: $red;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: 0.4s;
  &:hover {
    background-color: $tours_step_main_bg_gray;
  }
}

.cancel_btn {
  font-size: $font12;
  line-height: 150%;
  display: inline-flex;
  color: $gray-900;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: 0.4s;
  &:hover {
    background-color: $tours_step_main_bg_gray;
  }
}

.services_header_container {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;

  * {
    margin-bottom: unset !important;
  }
}

.selected_packages_container {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.details_services_section {
  padding: 24px 16px;
}

.section_disable_container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $z_index_second_floor;
  opacity: $main_disabled_container_bg_opacity;
  background-color: #fff;
  &.disable_transparent {
    background-color: transparent;
  }
}

.details_services_section_disabled {
  background-color: $section_disabled_bg_color;
  border-radius: 8px;
  border: $details_section_border;
}

.main_content_container {
  background-color: $white;
  padding: 0 16px;
}

.details_first_section {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 874px;
}

.request_note_part {
  width: 100%;
}

.address_part_container {
  .card-detail {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
}

.shooting_date_title {
  margin-bottom: 2px !important;
}

.to_be_confirmed {
  color: #4f5b67cc;
  font-size: $font12;
  font-weight: $fWeight400;
}

.shooting_time_container {
  display: flex;
  justify-content: space-between;
}

.tour_details_card_detail {
  .btn_main {
    margin-top: auto;
  }
  .card_buttons_container {
    margin-top: auto;
  }
}

.shooting_date_card {
  padding-bottom: 21px;
  .date-picker-input-container {
    label {
      font-size: 13px;
    }
  }
}

.requestCard {
  .avatar {
    font-size: 35px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.package_services_container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 2px;
  width: 100%;
  margin: 0 10px;
}

.package_services_in_summary {
  flex: 1 1;
  font-weight: 400;
  font-size: 10px !important;
  line-height: 14px;
  color: #000000;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.details_cards_container {
  row-gap: 30px;

  .cardCharge {
    margin-bottom: 10px;
  }
}

.later_charge_pay_btn {
  float: right;
}

@media (max-width: 767px) {
  .dates_part_container {
    width: 100%;
  }
  .address_part_container {
    width: 100%;
  }
}
