// Variables

// BREAKPOINTS

$main_mobile: 767px;

//   COLORS VARIABLES
$white: #fff;
$black: #000;
$primary_color: #e71b4c;
$secondary_color: #07364b;
$ghost-white: #f9f9f9;

// BORDERS

$nav_menu_border: 0.5px solid rgba(7, 54, 75, 0.33);

// BORDER RADIUSES
$primary_border_radius: 8px;

//   FONTS VARIABLES

$font40: 40px;
$font18: 18px;
$font16: 16px;
$font15: 15px;
$font14: 14px;
