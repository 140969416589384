.card {
  border: none;
  background: #ffffff;
  border-radius: 12px;
  padding: 16px 24px 24px 24px;
}

.requestCard {
  padding: 12px 16px;
  border: 1px solid $primary;
  max-width: 330px;
  @include border-radius(12px);
  display: flex;
  position: relative;

  .avatar {
    width: 62px;
    height: 62px;
    @include border-radius(4px);
    margin-right: 16px;
  }

  .mediaBody {
    .name {
      @include Montserrat(400);
      font-size: $font14;
      line-height: 150%;
      color: $gray-100;
      flex: 1;
    }

    p {
      @include Montserrat(400);
      font-size: $font12;
      line-height: 150%;
      color: $gray-600;
      margin: 0;
    }
  }

  .userType {
    position: absolute;
    top: -11px;
    right: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include Montserrat(500);
    font-size: $font12;
    line-height: 100%;
    color: $primary;
    padding: 4px 10px;
    background: $white;
    z-index: 5;
    border: 1px solid $primary;
    @include border-radius(12px);
  }
}

.card-detail {
  padding: 16px;
  background: $white;
  border: 1px solid $border;
  @include border-radius(8px);

  h3 {
    margin: 0 0 6px;
    @include Montserrat(500);
    font-size: $font18;
    color: $gray-800;
  }

  p {
    margin: 0;
    @include Montserrat(400);
    font-size: $font12;
    line-height: 150%;
    color: $gray-600;

    i {
      opacity: 0.5;
    }
  }

  .tour-details-card-text {
    @include Montserrat(500);
    font-size: $font12;
  }

  .edit-input {
    width: 100%;
    font-size: $font12;
  }

  .edit-btn {
    @include Montserrat(400);
    font-size: $font12;
    line-height: 150%;
    display: inline-flex;
    color: $red;
    text-decoration: none;
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .sale_price {
    outline: none;
    border: 1px solid $border;
    @include border-radius(8px);
    @include Montserrat(500);
    font-size: 16px;
    margin-left: 2px;
  }
}

.cardCharge {
  border: 1px solid $primary;
  border-radius: 8px;
  padding: 16px;
  max-width: 550px;

  h3 {
    @include Montserrat(600);
    font-size: $font18;
    line-height: 150%;
    color: $gray-800;
    margin-bottom: 20px;
  }

  .titleCharge {
    padding: 9px 0;
    border-bottom: 1px solid $body-color;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      @include Montserrat(600);
      font-size: $font14;
      line-height: 14px;
      color: $body-color;
      margin: 0;
    }
  }

  ul {
    li {
      display: flex;
      padding: 9px 0;
      border-bottom: 1px solid #dddddd;
      font-weight: 400;
      font-size: $font14;
      line-height: 14px;
      color: $body-color;

      span {
        flex: 1;
        font-weight: 400;
        font-size: $font14;
        line-height: 14px;
        color: $body-color;
      }

      a {
        font-weight: 400;
        font-size: $font14;
        line-height: 14px;
        color: $body-color;
      }
    }
  }

  .totalCard {
    margin: 12px 0;
    max-width: 257px;
    margin-left: auto;

    .amount {
      padding: 4px 0 15px 0;
      border-top: 1px solid $body-color;
      border-bottom: 1px solid $body-color;

      &.withoutTopBorder {
        border-top: none;
      }

      p,
      strong {
        margin: 0;
        display: flex;
        align-items: center;
        @include Montserrat(600);
        font-size: $font14;
        font-size: $font14;
        color: $body-color;

        span {
          flex: 1;
        }
      }
    }

    .total {
      padding: 6px 0;

      &.later_charge_total {
        border-top: 1px solid #000000;
      }

      p,
      strong {
        margin: 0;
        display: flex;
        align-items: center;
        @include Montserrat(600);
        font-size: $font14;
        font-size: $font14;
        color: $body-color;

        span {
          flex: 1;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .card {
    padding: 15px;
  }
}
