* {
  font-family: $font_family_dashboard_main !important;
}

.brandBanner {
  background: url(/assets/images/bgImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 36px;
  @include poppins(400);
}

.form-wrapper {
  max-width: 516px;
  margin: 0 auto;
  padding: 30px 30px 10px 30px;

  .cstTabs {
    margin-bottom: 30px;
  }

  .btn {
    @include border-radius(8px);
    height: 60px;
    font-size: $font20;
  }

  .cta {
    @include Montserrat(500);
    font-size: $font14;
    line-height: 150%;
    text-align: right;
    text-decoration-line: underline;
    color: $primary;
    display: inline-flex;
    margin-bottom: 16px;
  }

  p {
    @include Montserrat(500);
    font-size: $font14;
    line-height: 150%;
    color: $primary;
    margin: 23px 0 0;

    a {
      color: $primary;
      text-decoration-line: underline;
    }
  }

  .form-control,
  .form-select {
    height: 60px;
    border: 1px solid $gray100;
  }
}

@include media-breakpoint-down(md) {
  .brandBanner {
    background: none;
    min-height: auto;
  }

  .form-wrapper .btn,
  .form-wrapper .form-control,
  .form-wrapper .form-select {
    height: 42px;
  }
  .form-wrapper .btn {
    font-size: 16px;
  }
}

@include media-breakpoint-down(sm) {
  .brandBanner {
    padding: 15px;
  }

  .form-wrapper {
    padding: 15px;
  }
}
